.RoomDetailContainer {
    height: 100%;
    background-color: #f2f0e4;
    margin: -1.5em -1em;
}

.RoomDetailContainer .Head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8em 0.5em 1.5em 0.5em;
}

.Head .ArrowIcon {
    width: 15px;
    transform: rotate(-90deg);
    margin-top: -0.5em;
}

.Head .ProfileImage {
    width: 25px;
    border-radius: 0.8em;
    margin-left: 1.2em;
}

.Head span {
    font-size: 1em;
    margin-left: 0.5em;
}

.Head svg {
    font-size: 1.4em;
}

.RoomDetailCard {
    background-color: #ffffff;
    padding: 1em 1em 3em 1em;
    border-radius: 1.5em 1.5em 0 0;
    height: 90vh;
    overflow: auto;
}

.RoomDetailCard h6 {
    font-size: 0.675em;
    color: var(--textColor);
    font-weight: 500;
    margin-bottom: 0.3em;
}

.RoomDetailCard img {
    width: 70px;
    border-radius: 1.7em;
}

.MemberContainer {
    text-align: center;
    width: 70px;
    position: relative;
}

.MemberContainer .AudioIcon {
    position: absolute;
    right: -5px;
    bottom: 36px;
    z-index: 5;
    background-color: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px #cccccc;
}

.MemberContainer .AudioIcon svg {
    color: #28ae61;
}

.MemberContainer p {
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 500;
    color: #000000;
}

.MemberContainer span {
    font-size: 2.5em;
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #28ae61;
    color: #ffffff;
    margin-right: 0.1em;
    line-height: 0.8;
}

.Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
}

.Footer button {
    background-color: #e7e4d5;
    border: none;
    border-radius: 2em;
}

.Footer button img {
    width: 30px;
}

.Footer>button {
    display: flex;
    align-items: center;
    padding: 0.3em 1em;
    color: #28ae61;
    font-weight: 500;
}

.Footer>div {
    display: flex;
    align-items: center;
}

.Footer>div>button {
    line-height: 1;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Footer>div>button:first-child {
    font-size: 1.5em;
    margin-right: 0.5em;
}

.Footer>div>button:nth-child(2) {
    margin-right: 0.5em;
}

.Footer>div>button:nth-child(3) {
    font-size: 1.5em;
}