.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f2f0e4;
}

.Header img {
    width: 25px;
}

.NavItems img {
    margin: 0 0.8em;
}