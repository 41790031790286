.ExploreContainer {
    padding: 0 0.5em;
    height: 100%;
    overflow: auto;
    padding-top: 5.2em;
}

.ExploreContainer h6 {
    text-transform: uppercase;
    font-size: 0.975em;
    font-weight: 500;
    color: #bbbbbb;
    margin: 1.5em 0 0.5em 0;
}

.ShowMore {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f4f4f4 !important;
    border: none !important;
    color: #aaaaaa !important;
    box-shadow: 0 2px 3px #cccccc;
    font-weight: 500 !important;
}

.ShowMore span {
    font-size: 0.775em !important;
    margin-top: 0.4em;
    margin-left: 0.5em;
}

/* .Head img {
    width: 15px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
} */

.PeopleContainer {
    background-color: #ffffff;
    margin: 0 -0.5em;
    padding: 0.5em 0.5em 1.5em 0.5em;
    position: relative;
}

.PeopleContainer>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PeopleContainer img {
    width: 40px;
    border-radius: 1.1em;
}

.PeopleContainer h5 {
    color: var(--textColor);
    font-size: 1.1em;
    margin-bottom: 0.2em;
}

.PeopleContainer p {
    color: var(--textColor);
    font-size: 0.9em;
    margin: 0;
    display: block;
    display: -webkit-box;
    width: 173px;
    height: fit-content;
    line-height: 1.15;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #aaaaaa;
}

.PeopleContainer button {
    background-color: transparent;
    border: 2px solid var(--btnBgColor);
    border-radius: 2em;
    color: var(--btnBgColor);
    font-weight: 400;
    font-size: 0.975em;
    padding: 0.1em 1.2em;
}

.ConversationCard {
    background-color: #ffffff;
    border-radius: 0.5em;
    padding: 0.5em;
    box-shadow: 0 2px 3px #cccccc;
    
}

.ConversationCard h6 {
    color: var(--textColor);
    font-size: 1em;
    margin: 0;
    display: flex;
    align-items: center;
}

.ConversationCard h6 span {
    margin-right: 0.2em;
}

.ConversationCard p {
    margin: 0;
    color: #aaaaaa;
    line-height: 1.2;
    font-size: 0.8em;
}

.Header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: #f2f0e4;
    z-index: 5;
    padding: 0.5em;
}

.Head {
    text-align: center;
    padding: 0.5em;
    position: relative;
    margin-bottom: 0.5em;
}

.Head img {
    width: 15px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.Head h3{
    text-transform: uppercase;
    margin: 0;
    font-size: 1.2em;
}
