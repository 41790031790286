.RoomCardContainer {
    background-color: #ffffff;
    padding: 1em;
    border-radius: 1em;
    border: none;
    margin-top: 0.5em;
}

.RoomCardContainer h6 {
    font-size: 0.5em;
    color: var(--textColor);
    font-weight: 500;
}

.RoomCardContainer h2 {
    font-size: 0.7em;
    color: #000000;
    font-weight: 500;
    line-height: 1;
}

.RoomMembers img {
    width: 35px;
    height: 35px;
    border-radius: 0.35em;
}

.RoomMembers {
    display: flex;
    align-items: flex-start;
}

.RoomMembers p {
    font-size: 0.7em;
    font-weight: 400;
    line-height: 0.75;
    margin-bottom: 0.5em;
    color: var(--textColor);
}

.RoomMembers>div:first-child {
    position: relative;
    margin-right: 1.5em;
}

.RoomMembers>div:first-child>img:nth-child(2) {
    position: absolute;
    left: 0.6em;
    top: 0.6em;
}